
import React from "react";
import { withComponentFeatures } from 'universal-dashboard';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoadingButton from '@mui/lab/LoadingButton'

export const UDButtonGroupItem = withComponentFeatures(props => {
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    if (props.onClick == null) return;
    setLoading(true);
    props.onClick().then(() => setLoading(false)).catch(() => setLoading(false));
  }

  if (props.first) {
    if (props.showLoading) {
      return <LoadingButton
        href={props.href}
        onClick={handleClick}
        sx={props.sx}
        style={props.style}
        startIcon={props.icon && props.render(props.icon)}
        loading={loading}
        loadingIndicator={props.loadingIndicator && props.render(props.loadingIndicator)}
        loadingPosition={props.loadingPosition}
        variant="outlined"
      >
        {props.text}
      </LoadingButton>
    }

    return (<Button
      startIcon={props.icon && props.render(props.icon)}
      onClick={handleClick}
      href={props.href}
      sx={props.sx}
      style={props.style}
    >
      {props.text}
    </Button>)
  }

  return <MenuItem
    onClick={handleClick}
  >
    {props.icon && <ListItemIcon>{props.render(props.icon)}</ListItemIcon>}
    {props.text && <ListItemText>{props.text}</ListItemText>}
  </MenuItem>
})

export const UDButtonGroup = withComponentFeatures(props => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant={props.variant}
        fullWidth={props.fullWidth}
        ref={anchorRef}
        aria-label="split button"
        color={props.color}
        orientation={props.orientation}
        size={props.size}
        disabled={props.disabled}
        sx={props.sx}
        className={props.className}
      >
        {props.render({ ...children[0], first: true })}
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        role={undefined}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {children.slice(1).map((option) => props.render(option))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
})