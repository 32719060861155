import iziToast from 'izitoast/dist/js/iziToast.min.js'
require('izitoast/dist/css/iziToast.min.css')
require('@fortawesome/fontawesome-free/css/all.min.css')

const toaster = {
    show: (model) => {
        if (model.udIcon) {
            model.icon = model.udIcon.icon;
        }
        if (model.titleSize == "" && model.messageSize != "") {
            if (model.messageSize.toLowerCase() == "small") {
                model.messageSize = 13;
            }
            else if (model.messageSize.toLowerCase() == "large") {
                model.messageSize = 18;
            }
            else {
                model.messageSize = model.messageSize.replace("px", "");
            }
        }
        iziToast.show(model);
    },
    hide: (id) => {
        var toast = document.querySelector('#' + id);
        iziToast.hide({}, toast);
    },
    info: (model) => {
        iziToast.info(model);
    },
    success: (model) => {
        iziToast.success(model);
    },
    warning: (model) => {
        iziToast.warning(model);
    },
    error: (model) => {
        iziToast.error(model);
    },
    hideAll: () => {
        iziToast.destroy();
    }
}

export default toaster;